<template>
  <div class="account-content">
    <div class="reload-position" 
    v-if="!isProfilePatientRoute"
    >
      <Refresh 
        class="reload-icon"
        @click="reload()"
      />
    </div>

    <AccountPatientData
      :patient="patient"
      v-if="showAccountPatientData"
    />

    <PatientFinancialResume
      :patient="patient"
      :pendencies="pendencies"
      :payments="payments"
      :deposits="deposits"
      :withdraws="withdraws"
      :currentCredit="currentCredit"
      v-show-feature="'patient-credit'"
      v-if="hasPatientCreditFeature"
      v-can="'FiConCred1'"
    />
    <PatientCredit
      ref="patientCredit"
      :patient="patient"
      :categorySelected="categorySelected"
      v-show-feature="'patient-credit'"
      v-if="hasPatientCreditFeature"
      v-can="'FiConCred1'"
      @informations="handleGetResumeFinancial"
      @open-bill="(billId) => $emit('open-bill', billId)"
    />
    <AccountPending
      :patient="patient"
      :tissGuide="tissGuide"
      :clinicHealthPlan="clinicHealthPlan"
      :createGuide="createGuide"
      :createGuideSus="createGuideSus"
      @pendingItensLength="handleGetPendenciesQuantity"
      @setClinicHealthPlanToContent="setClinicHealthPlan"
    />
    <AccountHistory
      :patient="patient"
      :showModal="showModal"
    />
    <BillItemsModal
      :billId="billId"
      :patient="patient"
      :appointmentDate="appointmentDate"
      :checkedItems="checkedItems"
      @reload="reload"
      @clearItems="clearItems"
      @hidden="billId = null"
      @issueInvoice="issueInvoice"
      :creditPatientCategory="categorySelected"
    />
    <NewInvoiceModal />
    <ResumeInvoiceModal />
    <TissGuidesModalsContainer
      :patient="patient"
      :tissGuide="tissGuide"
      :susGuide="susGuide"
      :clinicHealthPlan="clinicHealthPlan"
      :getLastTissGuide="getLastTissGuide"
      :deleteTissGuide="deleteTissGuide"
      :clearTissGuide="clearTissGuide"
      :required_fields="clinicHealthPlan?.invoicing_tiss_setting?.tiss_information.tiss_required_custom_field"
      :getClinicHealthPlan="(invoicingTissSettingId) => {}"
      :setTissGuide="setTissGuide"
      :getGuide="getGuide"
      :checkedItems="checkedItems"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { handleGuideUpdate } from '@/utils/invoicingTissHelper'
import { showFeature, hasPermissionPatientCreditCategory } from '@/utils/permissionsHelp'
import financialApi from '@/modules/financial/api'

export default {
  props: {
    patient: Object,
    appointmentDate: Date,
    showAccountPatientData: {
      type: Boolean,
      default: true  
    } 
  },
  components: {
    AccountPatientData: () => import('./AccountPatientData'),
    AccountPending: () => import('./AccountPending'),
    AccountHistory: () => import('./AccountHistory'),
    BillItemsModal: () => import('@/modules/financial/modals/BillItemsModal'),
    Refresh: () => import('@/assets/icons/loading.svg'),
    TissGuidesModalsContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue'),
    NewInvoiceModal: () => import('@/modules/nfe/modals/NewInvoiceModal'),
    ResumeInvoiceModal: () => import('@/modules/nfe/modals/ResumeInvoiceModal'),
    PatientCredit: () => import('@/modules/patients/components/PatientCredit'),
    PatientFinancialResume: () => import('@/modules/patients/components/PatientFinancialResume')
},
  computed: {
    ...mapState('pendingItems', ['checkedItems']),
    isProfilePatientRoute() {
      return this.$route.path.includes('pacientes')
    },
    hasPatientCreditPermissions() {
      return this.hasPermissionPatientCreditCategory() && this.hasPatientCreditFeature
    },
    hasPatientCreditFeature() {
      return this.showFeature('Caução do paciente')
    }
  },
  mounted() {
    // this.getPendingAppointmentItems()
    this.getHistoryItemsFromPatient()

    if (this.hasPatientCreditPermissions) {
      this.getPatientDepositCategory()
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      tissGuide: {},
      susGuide: {},
      clinicHealthPlan: null,
      billId: null,
      currentCredit: 0,
      pendencies: 0,
      payments: 0,
      categorySelected: null,
      deposits: 0,
      withdraws: 0
    }
  },
  methods: {
    ...mapActions('historyItems', ['getHistoryItemsFromPatient']),
    ...mapActions('pendingItems', ['getPendingAppointmentItems', 'changeCheckedItems']),
    hasPermissionPatientCreditCategory,
    showFeature,
    async showModal(historyItem) {
      if (historyItem.type === 'bill') {
        this.billId = null
        this.billId = historyItem.bill_id
        this.$bvModal.show('bill-items-modal')
      } else if (historyItem.type === 'tissGuideItem') {
        await this.getGuide(historyItem.tiss_guide_id)
      }else if (historyItem.type === 'susGuideItem') {
        const isLoading = this.$loading.show()
        const { data } = await this.api.getSusGuideById(historyItem.guide_id);
        this.susGuide = data;
        if(historyItem.guide_type === "Guia BPA"){
          this.$bvModal.show('bpa-sus-guide-modal');
        } else {
          this.$bvModal.show('apac-sus-guide-modal');
        }
        isLoading.hide();
      }
    },
    reload() {
      this.getPendingAppointmentItems(this.patient.id)
      this.getHistoryItemsFromPatient(this.patient.id)
      if (this.$refs.patientCredit) {
        this.$refs.patientCredit.getCreditResume()
      }
    },
    clearItems() {
      this.changeCheckedItems([])
    },
    issueInvoice() {
      this.$bvModal.show('new-invoice-modal')
    },
    createGuide({ tissGuide, clinicHealthPlan }) {
      this.clinicHealthPlan = clinicHealthPlan
      this.tissGuide = tissGuide
    },
    createGuideSus({ susGuide, clinicHealthPlan }) {
      this.susGuide = {}
      this.clinicHealthPlan = clinicHealthPlan
      this.patient = susGuide.patient
      this.reload();
    },
    async getLastTissGuide(data) {
      if(data.isSus){
        this.changeCheckedItems([]);
        this.reload();
        return
      }

      if(data.other_expenses){
        this.reload()
        return
      } 
      if (data.id && !data.guide_id) {
        const tissGuide = await this.findByGuideIdReport(
          data.id
        )
        if(!data?.notReopen) this.getGuide(tissGuide)
      }
    },
    async findByGuideIdReport(id) {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.findByGuideIdReport(id)
        return data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async deleteTissGuide(tissGuide, decrease = false) {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteTissGuide(tissGuide?.id, decrease)
        this.$toast.success('Guia removida com sucesso!')
        this.reload()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    clearTissGuide() {
      this.tissGuide = {}
      this.clearItems()
      this.reload()
    },
    async getGuide(tissGuideId) {
      if(typeof tissGuideId === 'object') tissGuideId = tissGuideId.id
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideById(tissGuideId)
        this.tissGuide = response.data
        await this.getClinicHealthPlan(this.tissGuide.invoicing_tiss_setting_id)
        this.$bvModal.show(handleGuideUpdate(this.tissGuide.guide_type))
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getClinicHealthPlan(invoicingTissSettingId) {
      if (!this.clinic.id || !invoicingTissSettingId) return
      try {
        const response = await this.api.findByClinicIdAndInvoicingTissSettingId(
          this.clinic.id,
          invoicingTissSettingId
        )
        this.clinicHealthPlan = response.data
        this.tissRequiredCustomField =
          this.clinicHealthPlan.invoicing_tiss_setting?.tiss_information.tiss_required_custom_field
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    setTissGuide(tissGuide){
      this.tissGuide = null
      this.tissGuide = tissGuide
    },

    async getPatientDepositCategory() {
      if (this.clinic?.id) {
        const response = await financialApi.getCreditPatientCategory(this.clinic.id)

        if (response) {
          this.categorySelected = response.data
        }
      }
    },
    handleGetPendenciesQuantity(pendencies) {
      this.pendencies = pendencies
    },
    handleGetResumeFinancial({ deposits, withdraws, currentCredit, payments }) {
      this.deposits = deposits
      this.withdraws = withdraws
      this.currentCredit = currentCredit
      this.payments = payments
    },
    setClinicHealthPlan(clinicHealthPlan) {
      this.clinicHealthPlan = clinicHealthPlan
    }
  },
  watch: {
    patient: {
      handler() {
        this.reload()
      },
      immediate: true
    }
  }

}
</script>

<style lang="scss" scoped>
.account-content {
  padding: 1.5rem 0;
}

.reload-icon {
  width: 24px;
  height: 24px;
  stroke: var(--dark-blue);
  cursor: pointer;

  :hover{
    stroke: var(--type-placeholder);
  }
}

.reload-position{
  width: 100%;
  display: flex;
  justify-content: end;
  right: 0;
  margin-top: 8px;
  margin-bottom: -40px;
}
</style>
